<template>
  <el-table
    :data="filteredReport5.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
  >
    <el-table-column align="center" width="250" prop="marka" label="Avto" />
    <el-table-column
      align="center"
      width="250"
      prop="get_truck_number"
      label="Mashina raqami"
    />

    <el-table-column
      align="center"
      width="250"
      prop="capacity_kg"
      label="Gruzo-t"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.capacity_kg) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="capacity_m3"
      label="Obyom m3"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.capacity_m3) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="bought_price"
      label="Narx"
    >
      <template slot-scope="scope">
        {{ scope.row.bought_price | toRoundFloat }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="get_this_month_zayavkas_distance"
      label="JAMI km"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.get_this_month_zayavkas_distance) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="daily_amortization.this_month_amortization"
      label="Amartizatsiya"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.daily_amortization.this_month_amortization) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="amortization_km"
      label="Amartizatsiya za km"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.amortization_km) }}
      </template>
    </el-table-column>

    <el-table-column align="center" width="100" prop="gsm_km" label="GSM za km">
      <template slot-scope="scope">
        {{ Math.round(scope.row.gsm_km) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="tire_km"
      label="Balon za km"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.tire_km) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="remont_km"
      label="Remont za km"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.remont_km) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="salary_km"
      label="Oklad za km"
    >
      <template slot-scope="scope">
        {{ Math.round(scope.row.salary_km) }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="premiya_km"
      label="Premiya za km"
    >
      <template slot-scope="scope">
        {{ scope.row.premiya_km | toRoundFloat }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="get_this_month_insurance_payment"
      label="Sug"
    >
      <template slot-scope="scope">
        {{ scope.row.get_this_month_insurance_payment | toRoundFloat }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="get_this_month_license_payment"
      label="Litsen"
    >
      <template slot-scope="scope">
        {{
          Math.round(scope.row.get_this_month_license_payment) | toRoundFloat
        }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="get_this_month_advert_permit_payment"
      label="Rek"
    >
      <template slot-scope="scope">
        {{
          Math.round(scope.row.get_this_month_advert_permit_payment)
            | toRoundFloat
        }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="100"
      prop="total"
      label="JAMI Harajat za km"
    >
      <template slot-scope="scope">
        {{ scope.row.total | toRoundFloat }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat, numberWithSpaces } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport5']),
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },

  filters: { toRoundFloat, numberWithSpaces },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Birlik xarajatlar' }])
    })
    this.getReports(this.$route.params.id)
    // console.log('Table 5 mounted')
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  },

  methods: {
    ...mapActions(['getReports'])
  }
}
</script>

<style></style>
